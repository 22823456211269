// do imports
import $ from 'jquery';
import jQuery from 'jquery';
import 'what-input';

// setup jQuery
window.$ = $;
window.jQuery = $;

// do reqs
require('owl.carousel/dist/owl.carousel.min.js');
require('foundation-sites');

// fire up the framework
$(document).foundation();

// fire custom components
$(document).ready(function(){

    $('.client-selection').owlCarousel({
        autoplay:true,
        autoplayTimeout:10000,
        autoplayHoverPause:true,
        autoplaySpeed:2500,
        autoWidth:true,
        center:true,
        fluidSpeed:500,
        loop:true,
        margin:16,
        nav:false,
        smartSpeed:500,
        stagePadding: 16,
        responsive:{
            0:{
                items:2
            },
            320:{
                items:2
            },
            640:{
                items:3
            },
            860:{
                items:4
            },
            1024:{
                items:5
            },
            1320:{
                items:7
            },
            1560:{
                items:9
            }
        }
    })

});  